import React from 'react'
import { darken, getLuminance, lighten } from 'polished'
import styled, { css } from 'styled-components'
import { THEMES, SIZES } from './constants'
import { FONT_FAMILIES } from '../../../styles/Theme'

export interface BaseProps {
  backgroundColor: string;
  height: number;
  children: any;
  color?: string;
  strokeColor?: string;
  dropShadowColor?: string;
  shadowColor?: string;
  fontSize?: string;
  round?: boolean;
  disabled?: boolean;
}

const getTextColor = (props: BaseProps) => {
  if (props.color) {
    return props.color
  }
  if (getLuminance(props.backgroundColor) <= 0.5) return '#fff'
  return '#000'
}

const getTextSize = (props: BaseProps) => {
  if (props.fontSize) {
    return props.fontSize
  }
  return `${props.height / 2.5}px`
}

const getPadding = (props: BaseProps) => `${0.001 * props.height}px ${props.height / 2}px`

export const getSizeComparedToBase = (
  props: BaseProps,
  originalValue: number
) => (props.height * originalValue) / 64

export const getShadows = (props: BaseProps) => {
  const light = getLuminance(props.backgroundColor)
  const shadowColor =
    props.shadowColor || light
      ? darken(0.2, props.backgroundColor)
      : lighten(0.2, props.backgroundColor)
  const dropShadowColor = props.dropShadowColor || `rgba(0,0,0,0.3)`
  const strokeColor =
    props.strokeColor || light
      ? lighten(0.1, props.backgroundColor)
      : darken(0.1, props.backgroundColor)
  return css`
    box-shadow: 0 ${getSizeComparedToBase(props, 5)}px 0 ${shadowColor},
      0 ${getSizeComparedToBase(props, 12)}px 0 ${dropShadowColor},
      inset 0 0px 0px ${getSizeComparedToBase(props, 5)}px ${strokeColor};

    :active {
      transform: translateY(${getSizeComparedToBase(props, 7)}px);
      box-shadow: 0 ${getSizeComparedToBase(props, 5)}px 0 ${shadowColor},
        inset 0 0px 0px ${getSizeComparedToBase(props, 5)}px ${strokeColor};
    }
  `
}

const StyledButton = styled.div`
  position: relative;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  ${FONT_FAMILIES.rammetto};
  border-radius: 1000px;
  cursor: pointer;
  user-select: none;
  ${(props: BaseProps) => css`
    background-color: ${props.backgroundColor};
    height: ${props.height}px;
    margin-bottom: ${getSizeComparedToBase(props, 12)}px;
    color: ${getTextColor(props)};
    font-size: ${getTextSize(props)};
    padding: ${getPadding(props)};
    ${getShadows(props)};
    filter: ${props.disabled ? 'grayscale(100%)' : 'none'};
  `};
  ${(props: BaseProps) =>
    props.round &&
    css`
      width: ${props.height}px;
      padding: 0;
    `}

  svg {
    height: 100%;
    width: 100%;
  }
`

// @ts-ignore
export interface ButtonProps extends BaseProps {
  size?: "x-small" | "small" | "medium" | "regular" | "large" | "x-large";
  theme?: "confirm" | "primary" | "the-pink" | "purple";
  height?: number;
  backgroundColor?: string;
  children?: any;
  disabled?: boolean;
  onClick?: () => void;
}

const Button = (props: ButtonProps) => (
    // @ts-ignore
    <StyledButton
      // @ts-ignore
      height={props.size && SIZES[props.size]}
      {...props}
      {...(props.theme ? THEMES[props.theme] : {})}
    />
  )

export default Button
