import { useLocation } from "@reach/router"
import React, { useEffect, useMemo, useRef, useState } from "react"
import ExitIntentModal from "."

const useExitIntent = (auto = true) => {
  const [displayed, setDisplayed] = useState(false)
  const location = useLocation()
  const [enabled] = useState(auto)
  const timeout = useRef<any>()

  const shouldDisplay = () => {
    if (
      !new RegExp(/^(\/[a-z].*)?(\/parents|\/teachers|\/blog|\/curriculum)/).test(
        location.pathname
      )
    )
      return false
    const lastDisplay = parseInt(
      localStorage.getItem("last-exitintent") || "",
      10
    )
    const dismissedAt = parseInt(
      localStorage.getItem("last-exit-intent-dismissed") || "",
      10
    )
    if (dismissedAt > 0) return false
    if (!lastDisplay) return true
    if (Date.now() - lastDisplay > 80 * 1000) {
      return true
    }
    return false
  }

  const display = (force?: boolean) => {
    if (!force && !shouldDisplay()) return
    if (typeof window === "undefined") return
    localStorage.setItem("last-exitintent", `${Date.now()}`)
    setDisplayed(true)
  }

  const trigger = evt => {
    if (evt.relatedTarget === null) {
      display()
    }
  }

  const hide = () => {
    localStorage.setItem("last-exit-intent-dismissed", `${Date.now()}`)
    setDisplayed(false)
  }

  const toggle = () => setDisplayed(d => !d)

  const mountEvent = () => {
    setTimeout(() => {
      document.addEventListener("mouseout", trigger)
    }, 5000)
    timeout.current = setTimeout(() => {
      display()
    }, 80000)
  }

  useEffect(() => {
    if (enabled) {
      mountEvent()
    } else {
      clearTimeout(timeout.current)
      timeout.current = null
      document.removeEventListener("mouseout", trigger)
    }
    return () => {
      clearTimeout(timeout.current)
      timeout.current = null
      document.removeEventListener("mouseout", trigger)
    }
    // eslint-disable-next-line
  }, [enabled])

  const component = useMemo(() => {
    if (displayed) return <ExitIntentModal dismiss={hide} />
    return null
  }, [displayed])

  return {
    component,
    display,
    hide,
    toggle,
  }
}

export default useExitIntent
