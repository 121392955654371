import useExitIntent from "components/ExitIntent/useExitIntent"
import { graphql, useStaticQuery } from "gatsby"
import { GatsbyImage } from "gatsby-plugin-image"
import { Facebook, Twitter } from "grommet-icons"
import React from "react"
import styled from "styled-components"
import getGatsbyImage from "utils/getGatsbyImage"
import Link from "./Link"

const Wrapper = styled.div`
  height: 200px;
  width: 100%;
  background-color: #fff;
  color: #757575;
  padding: 15px 20px;
  box-sizing: border-box;
  font-size: 1rem;
  max-width: 1100px;
  margin: 0 auto;
`

const ActionLink = styled.div`
  cursor: pointer;
  user-select: none;
`

const Links = styled.div`
  display: flex;
  justify-content: space-between;
  margin-bottom: 20px;
  a {
    text-decoration: none;
    color: inherit;
  }
  @media (max-width: 750px) {
    flex-direction: column;
    > * { margin-bottom: 20px; }
  }
`

const Group = styled.div`
  display: flex;
  flex-direction: column;
  > * { margin-bottom: 5px; }
  > :last-child {
    margin-bottom: 0px;
  }
`

const InlineGroup = styled.div`
  display: flex;
  > * {
    margin-right: 8px;
  }
  > :last-child {
    margin-right: 0;
  }
`

const Company = styled.div`
  display: flex;
  margin-top: 50px;
  margin-bottom: 30px;
  > :first-child {
    width: 150px;
  }
  img {
    object-fit: contain !important;
  }
  @media (max-width: 510px) {
    flex-direction: column;
    > :first-child {
      margin-bottom: 15px;
    }
    > :nth-child(2) {
      align-items: flex-start;
    }
  }
`

const Info = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
  align-items: center;
`

interface IProps {
  linkGroups?: {
    links: {
      id: number
      target: string
      onClick: () => void
      placeholder: string
      external: boolean
    }[]
  }[]
  company?: {
    logo: any
    copyright: string
    registrationNumber: string
  }
}

const Footer = (props: IProps) => (
  <Wrapper>
    <Links>
      {(props.linkGroups || []).map(({ links }, i) => (
        <Group key={i}>
          {links.map(link => link.target ? (
            <Link key={link.id} {...link} />
          ) : <ActionLink key={link.id} onClick={link.onClick}>{link.placeholder}</ActionLink>)}
        </Group>
      ))}
      <InlineGroup>
        <a href="https://twitter.com/nightzookeeper">
          <Twitter color="#757575" />
        </a>
        <a href="https://www.facebook.com/nightzookeeper">
          <Facebook color="#757575" />
        </a>
      </InlineGroup>
    </Links>
    {props.company && (
      <Company>
        {props.company.logo && (
          <GatsbyImage
            image={getGatsbyImage(props.company.logo.localFile)}
            alt="Logo"
          />
        )}
        <Info>
          <div>{props.company.copyright}</div>
          <div>{props.company.registrationNumber}</div>
        </Info>
      </Company>
    )}
  </Wrapper>
)

const FooterStrapi = (props: IProps) => {
  const { component, display } = useExitIntent(false)

  const { strapiHomepage } = useStaticQuery(graphql`
    {
      strapiHomepage {
        footer {
          linksGroups {
            id
            Link {
              id
              target
              placeholder
              external
            }
          }
          companyRegistration
          copyright
          logo {
            localFile {
              childImageSharp {
                gatsbyImageData(width: 150, placeholder: TRACED_SVG)
              }
            }
          }
        }
      }
    }
  `)

  const newLinkGroups = props.linkGroups && props.linkGroups.length > 0
  ? props.linkGroups
  : strapiHomepage.footer.linksGroups.map(linkGroup => ({
      links: linkGroup.Link.map(l => ({ ...l })),
    }))

  newLinkGroups[0].links.push({
    id: 'newsletter',
    placeholder: 'Sign up to newsletter',
    onClick: () => { display(true) }
  })

  const defaultProps = {
    linkGroups: newLinkGroups,
    company: props.company || {
      copyright: strapiHomepage.footer.copyright,
      registrationNumber: strapiHomepage.footer.companyRegistration,
      logo: strapiHomepage.footer.logo,
    },
  }

  return <>
    { component }
    <Footer {...props} {...defaultProps} />
  </>
}

const defaultProps = {
  linkGroups: [],
  company: null,
}

Footer.defaultProps = defaultProps
FooterStrapi.defaultProps = defaultProps

export default FooterStrapi
