import { useApolloClient } from "@apollo/client/react"
import { useLocation } from '@reach/router'
import { graphql, useStaticQuery } from "gatsby"
import { GatsbyImage } from "gatsby-plugin-image"
import gql from "graphql-tag"
import { FormClose } from "grommet-icons"
import React, { useMemo, useState } from "react"
import styled, { useTheme } from "styled-components"
import getGatsbyImage from "utils/getGatsbyImage"
import useHubspotTracking from "../../hooks/useHubspotTracking"
import Apollo from "../../providers/Apollo"
import Button from "../UI/Button"
import Input from "../UI/Input"

const Overlay = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.4);
  z-index: 100;
  display: flex;
  justify-content: center;
  align-items: center;
`

const Wrapper = styled.div`
  position: relative;
  max-width: 350px;
  margin: 0 auto;
  background-color: #fff;
  border: 2px solid ${props => props.theme.primary};
  color: ${props => props.theme.primary};
  padding: 35px 20px 15px 20px;
  border-radius: 14px;
  text-align: center;
  h3 {
    font-size: 1.4rem;
    margin: 0;
    font-weight: bold;
  }
  > :last-child {
    color: #aeaeae;
    font-size: 0.8rem;
  }
`

const QuitButton = styled.div`
  position: absolute;
  top: 0;
  right: 0;
  transform: translate(20%, -20%);
`

const Form = styled.div`
  > * {
    margin-bottom: 12px;
  }
  > :last-child {
    margin-bottom: 0;
  }
`

interface IProps {
  dismiss: () => void
}

const ExitIntentModal = (props: IProps) => {
  const theme: any = useTheme()
  const location = useLocation()
  const { identify } = useHubspotTracking()
  const [formData, setFormData] = useState<any>({
    firstName: "",
    lastName: "",
    email: "",
  })
  const client = useApolloClient()

  const { file } = useStaticQuery(graphql`
    {
      file(relativePath: { eq: "free-resources.jpg" }) {
        childImageSharp {
          gatsbyImageData(placeholder: BLURRED, width: 260)
        }
      }
    }
  `)

  const canSubmit = useMemo(() => {
    const emailRe = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
    return (
      formData.firstName !== "" &&
      formData.lastName !== "" &&
      emailRe.test(formData.email)
    )
  }, [formData])

  const submit = async () => {
    if (!canSubmit) return
    const re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
    if (!re.test(String(formData.email).toLowerCase())) return
    await client.mutate({
      mutation: gql`
        mutation($name: String, $email: String!) {
          submitExitIntentPopup(name: $name, email: $email)
        }
      `,
      variables: {
        email: formData.email,
        name: `${formData.firstName} ${formData.lastName}`,
      },
    })
    // analytics && analytics.logEvent('submit_exit_intent', { email })
    identify({
      email: formData.email,
      firstname: formData.firstName,
      lastname: formData.lastName,
    })
    try {
      if (typeof window !== 'undefined') {
        window.postMessage({
          event: 'ExitIntentSubmitted',
          payload: {
            email: formData.email,
            firstName: formData.firstName,
            lastName: formData.lastName
          }
        }, location.origin)
      }
    } catch (err) {
      console.error(err)
    }
    props.dismiss()
  }

  return (
    <Overlay>
      <Wrapper>
        <QuitButton>
          <Button
            round
            theme="primary"
            disabled
            size="regular"
            onClick={props.dismiss}
          >
            <FormClose color="#fff" size="12px" />
          </Button>
        </QuitButton>
        <h3>Free Gift</h3>
        <p>Free creative writing resource</p>
        <GatsbyImage image={getGatsbyImage(file)} alt="Free Resources" />
        <p>Simply enter your details here:</p>
        <Form>
          <Input
            borderColor={theme.primary}
            placeholder="First Name"
            value={formData.firstName}
            onChange={e =>
              setFormData(f => ({ ...f, firstName: e.target.value }))
            }
          />
          <Input
            borderColor={theme.primary}
            placeholder="Last Name"
            value={formData.lastName}
            onChange={e =>
              setFormData(f => ({ ...f, lastName: e.target.value }))
            }
          />
          <Input
            borderColor={theme.primary}
            placeholder="Enter email to claim"
            value={formData.email}
            onChange={e => setFormData(f => ({ ...f, email: e.target.value }))}
          />
          <Button
            theme="primary"
            size="small"
            disabled={!canSubmit}
            onClick={submit}
          >
            Get Offer
          </Button>
        </Form>
        <p>
          By entering your email, you give Night Zookeeper permission to contact
          you about their product and services.
        </p>
      </Wrapper>
    </Overlay>
  )
}

export default (props: IProps) => (
  <Apollo>
    <ExitIntentModal {...props} />
  </Apollo>
)
