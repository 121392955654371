import { useEffect, useState } from "react"
import { useLocation } from '@reach/router'
import Cookies from 'js-cookie'

const useReferral = () => {
  const [code, setCode] = useState<string | null>(null)
  const location = useLocation()

  useEffect(() => {
    const code = getCode()
    if (code) { setCode(code) }
    const urlParams = new URLSearchParams(location.search)
    const urlCode = urlParams.get('referralCode')
    if (urlCode) {
      setCode(urlCode)
    }
  }, [])

  useEffect(() => {
    saveCode(code)
  }, [code])

  const getCode = () => Cookies.get('referralCode') || localStorage.getItem('referralCode')

  const saveCode = (code) => {
    if (code) {
      Cookies.set('referralCode', code)
      return localStorage.setItem('referralCode', code)
    }
  }

  return code
}

export default useReferral
