import { navigate } from 'gatsby'
import React from "react"
import styled from "styled-components"

interface IProps {
  label: string
  links?: {
    placeholder: string
    target: string
    external: boolean
    onClick?: () => void
  }[]
}

const Wrapper = styled.div`
  position: relative;
  > :nth-child(2) {
    position: absolute;
    top: 0;
    height: 55px;
    width: 120%;
    left: 50%;
    transform: translateX(-50%);
  }
  > :first-child {
    user-select: none;
  }
  > :last-child {
    display: none;
    opacity: 0;
    transition: opacity 0.5s 0.2s;
  }
  :hover {
    > :last-child {
      opacity: 1;
      transition: opacity 0.5s 0.2s;
      display: block;
    }
  }
`

const Menu = styled.ul`
  position: absolute;
  background-color: #f8f8f8;
  //background-color: rgba(0,0,0,0.8);
  color: #4f4f4f;
  //color: #fff;
  width: max-content;
  top: 55px;
  //top: 39px;
  left: 50%;
  transform: translateX(-50%);
  padding: 0 10px;
  list-style-type: none;
  margin: 0;
  padding: 0;
  font-size: 0.8em;
  box-shadow: 0 4px 2px rgb(0 0 0 / 30%);
  padding: 10px 0;
  > :first-child > :first-child {
    border-top: 1px solid #4f4f4f22;
  }
  li {
    border-bottom: 1px solid #4f4f4f22;
  }
  li {
    padding: 6px 10px;
    :hover {
      background-color: rgba(0, 0, 0, 0.05);
    }
  }
  @keyframes animateIn {
    from {
      opacity: 0;
      transform: translateX(-50%) translateY(20px);
    }
    to {
      opacity: 1;
      transform: translateX(-50%) translateY(0);
    }
  }
  animation: animateIn forwards 0.3s;
  :before {
    content: "";
    position: absolute;
    top: -15px;
    left: 50%;
    transform: translateX(-50%);
    width: 0;
    height: 0;
    border-style: solid;
    border-width: 0 15px 15px 15px;
    border-color: transparent transparent #f8f8f8 transparent;
  }
`

const MenuItem = styled.div`

`

const Dropdown = (props: IProps) => (
  <Wrapper>
    <div>{props.label}</div>
    <div />
    <Menu onClick={e => e.stopPropagation()}>
      {(props.links || []).map((l, i) => (
        <MenuItem {...l} onClick={() => navigate(l.to)}>
          <li key={i} className="dropdown-el">
            {l.placeholder}
          </li>
        </MenuItem>
      ))}
    </Menu>
  </Wrapper>
)

Dropdown.defaultProps = {
  links: [],
}

export default Dropdown
