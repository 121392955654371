import { createContainer } from "lib/unstated"
import { useEffect, useState } from "react"
import Cookies from "js-cookie"
import { useApolloClient } from "@apollo/client"
import gql from "graphql-tag"
import config from "../../config"
import { getToken } from "./Apollo"

interface IAppCurrentUser {
  _id: string
  type: "parent" | "teacher" | "tutor" | "student" | "commenter"
  email?: string
  username?: string
  name?: string
  sharedSecretKey?: string
}

interface IToken {
  accessToken: {
    value: string
    expiresAt: Date
  }
  refreshToken: {
    value: string
    expiresAt: Date
  }
}

const USER_LOCALSTORAGE_KEY = "nzk-current-user"
const COOKIE_CONFIG = {
  name: "nzk-edu-token",
  options:
    config.env === "production"
      ? { domain: "nightzookeeper.com", secure: true, expires: 7 }
      : {},
}

const AuthenticationState = () => {
  const [isLoggedIn, setIsLoggedIn] = useState(false)
  const client = useApolloClient()
  const [currentUser, setCurrentUser] = useState<IAppCurrentUser | null>(null)
  const [token, setToken] = useState<IToken | null>(null)

  useEffect(() => {
    const token = getToken()
    setIsLoggedIn(token !== undefined)
  }, [])

  useEffect(() => {
    if (currentUser && currentUser.name) {
      localStorage.setItem(USER_LOCALSTORAGE_KEY, JSON.stringify(currentUser))
    }
  }, [currentUser])

  const getCurrentUser = async () => {
    const cachedCurrentUser = localStorage.getItem(USER_LOCALSTORAGE_KEY)
    if (cachedCurrentUser) {
      setCurrentUser(JSON.parse(cachedCurrentUser))
      return
    }
    const { data } = await client.query({
      query: gql`
        {
          me {
            _id
            username
            name
            email
            type
          }
        }
      `,
      fetchPolicy: "network-only",
    })
    setCurrentUser(data.me)
  }

  const setCookie = token => {
    Cookies.set(
      COOKIE_CONFIG.name,
      JSON.stringify(token),
      COOKIE_CONFIG.options
    )
  }

  useEffect(() => {
    if (isLoggedIn) {
      getCurrentUser()
    }
  }, [isLoggedIn])

  const login = (
    email: string,
    password: string
  ): Promise<{ error: { message: string } } | void> => {
    return fetch("https://oauth.nightzookeeper.com/oauth/token", {
      method: "POST",
      headers: {
        "Content-Type": "application/x-www-form-urlencoded;charset=UTF-8",
      },
      body: new URLSearchParams({
        username: email,
        password,
        client_id: "ijosdjia0asdasd0asda09",
        grant_type: "password",
      }),
    })
      .then(res => res.json())
      .then(data => {
        if (data.message === "Invalid grant: user credentials are invalid") {
          return { error: { message: "Email or password is incorrect" } }
        }
        setCurrentUser(data.user)
        const token: IToken = {
          accessToken: {
            value: data.accessToken,
            expiresAt: new Date(data.accessTokenExpiresAt),
          },
          refreshToken: {
            value: data.refreshToken,
            expiresAt: new Date(data.refreshTokenExpiresAt),
          },
        }
        setToken(token)
        setCookie(token)
        setIsLoggedIn(true)
      })
  }

  const logout = () => {
    Cookies.remove(COOKIE_CONFIG.name, COOKIE_CONFIG.options)
    localStorage.removeItem(USER_LOCALSTORAGE_KEY)
    setIsLoggedIn(false)
    setToken(null)
    setCurrentUser(null)
  }

  return {
    isLoggedIn,
    currentUser,
    token,
    login,
    logout,
  }
}

const AuthenticationContainer = createContainer(AuthenticationState)

export const AuthenticationStateProvider = AuthenticationContainer.Provider

export const useAuthenticationState = AuthenticationContainer.useContainer
