const useUserPreferences = () => {
  const setFavouriteLang = (locale) => {
    if (typeof window === 'undefined') return null
    localStorage.setItem('lang', locale)
  }

  const getFavouriteLang = () => {
    if (typeof window === 'undefined') return null
    localStorage.getItem('lang')
  }

  return {
    setFavouriteLang,
    getFavouriteLang
  }
}

export default useUserPreferences
