import styled from 'styled-components'

export default styled.input`
  font-size: 1rem;
  color: rgb(29, 29, 29);
  border-radius: 8px;
  padding: 8px 12px;
  background-color: white;
  box-shadow: rgb(0 0 0 / 10%) 0px 3px 2px;
  display: inline-block;
  width: 100%;
  outline: none;
  border: 1.5px solid ${(props: { borderColor?: string }) => props.borderColor || 'rgb(248, 178, 156)'};
`
