import { useLocation } from "@reach/router"
import { useEffect } from "react"

const useHubspotTracking = () => {
  const location = useLocation()
  let _hsq: any[] = []
  let _hsp: any[] = []
  if (typeof window !== "undefined") {
    // @ts-ignore
    _hsq = window._hsq || []
    // @ts-ignore
    _hsp = window._hsp || []
    // @ts-ignore
    window._hsq = _hsq
    // @ts-ignore
    window._hsp = _hsp
  }



  const initCookiesPreferences = () => {
    _hsp.push([
      // @ts-ignore
      "addPrivacyConsentListener",
      // @ts-ignore
      function (consent) {
        if (
          (window as any).dataLayer.filter(d => d.event === "userPrefUpdate")
            .length === 2
        )
          window.postMessage(
            {
              message: "consent_given",
              consentStatuses: {
                analytics: consent.categories.analytics,
                essential: consent.categories.functionality,
              },
            },
            location.origin
          )
      },
    ])
  }

  const identify = payload => {
    try {
      _hsq.push([
        "identify",
        {
          ...payload,
        },
      ])
    } catch (err) {
      console.error(err)
    }
  }

  const trackCustomEvent = (eventName, properties) => {
    _hsq.push(["trackCustomBehavioralEvent", {
      name: eventName,
      properties
    }])
  }

  const trackPage = path => {
    try {
      _hsq.push(["setPath", path])
      _hsq.push(["trackPageView"])
    } catch (err) {
      console.error(err)
    }
  }

  useEffect(() => {
    initCookiesPreferences()
  }, [])

  return {
    _hsq,
    identify,
    trackPage,
    trackCustomEvent
  }
}

export default useHubspotTracking
