import { createGlobalStyle } from 'styled-components'
import { FONT_FAMILIES } from '../styles/Theme'

const GlobalStyle = createGlobalStyle`
  body {
    padding: 0;
    margin: 0;
    ${FONT_FAMILIES.libreBaskerville};
    font-size: 16px;
  }
  * {
    box-sizing: border-box;
  }
  a {
    text-decoration: none;
    color: inherit;
  }
  h1, h2, h3, h4, h5, h6 {
    font-weight: 400;
    line-height: 1.1;
  }
`

export default GlobalStyle

