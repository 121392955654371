import { useEffect } from "react"
import { useAuthenticationState } from "../providers/AuthenticationProvider"
import useHubspotTracking from "./useHubspotTracking"

export interface ICurrentUser {
  _id: string
  username: string
  name: string
  type: string
}

const useCurrentUser = () => {
  const { currentUser } = useAuthenticationState()
  const { identify } = useHubspotTracking()

  useEffect(() => {
    const init = async () => {
      if (currentUser?.email) {
        identify({
          email: currentUser.email,
        })
      }
    }
    if (currentUser) init()
  }, [currentUser])

  return currentUser
}

export default useCurrentUser
