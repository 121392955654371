import React, { useState } from 'react'
import LoginModal from '.'

const useLoginModal = (): [any, () => void] => {
  const [displayed, setDisplayed] = useState(false)

  return [
    displayed && <LoginModal dismiss={() => setDisplayed(false)}/>,
    () => setDisplayed(d => !d)
  ]
}

export default useLoginModal
