interface Theme {
  backgroundColor: string;
  color: string;
  strokeColor: string;
  shadowColor: string;
}

export const THEMES: { [key: string]: Theme } = {
  primary: {
    backgroundColor: '#009EE2',
    color: '#fff',
    strokeColor: '#00C5ED',
    shadowColor: '#025899'
  },
  confirm: {
    backgroundColor: '#8CC63F',
    color: '#fff',
    strokeColor: '#AFD752',
    shadowColor: '#579800'
  },
  'the-pink': {
    backgroundColor: '#E40071',
    color: '',
    shadowColor: '',
    strokeColor: ''
  },
  'purple': {
    backgroundColor: '#701EA8',
    color: '',
    shadowColor: '#4E1B6D',
    strokeColor: '#9026C1'
  }
}

export const SIZES = {
  'xx-large': 142,
  'x-large': 109,
  large: 71,
  regular: 50,
  small: 39,
  'x-small': 30
}
