import {
  Facebook,
  Instagram,
  Link as LinkIcon,
  Pinterest,
  Twitter,
} from "grommet-icons"
import React, { useState } from "react"
import styled from "styled-components"

const Wrapper = styled.div`
  position: relative;
`

const Overlay = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
`

const Trigger = styled.div`
  height: 100%;
  display: flex;
  align-items: center;
  cursor: pointer;
  > * {
    margin-right: 6px;
  }
  > :last-child {
    margin-right: 0;
  }
`

const Dropdown = styled.div`
  position: absolute;
  bottom: -30px;
  right: 0;
  transform: translateY(100%);
  background-color: #fff;
  color: #595959;
  width: 300px;
  padding: 20px;
  font-size: 1rem;
  > * {
    margin-bottom: 10px;
  }
  background-color: #f8f8f8;
  box-shadow: 0 4px 2px rgb(0 0 0 / 30%);
  :before {
    content: "";
    position: absolute;
    top: -15px;
    right: 0;
    width: 0;
    height: 0;
    border-style: solid;
    border-width: 0 15px 15px 15px;
    border-color: transparent transparent #f8f8f8 transparent;
  }
`

const Title = styled.div`
  font-weight: normal;
  font-size: 1.5rem;
  margin-bottom: 12px;
`

const Element = styled.a`
  display: flex;
  > :first-child {
    margin-right: 6px;
  }
`

const Social = styled.div`
  height: 20px;
  width: 20px;
  display: flex;
  align-items: center;
  justify-content: center;
`

const CopyLink = styled.div`
  > :first-child {
    margin-bottom: 6px;
  }
  input {
    border: 1px solid rgba(0, 0, 0, 0.2);
    padding: 10px;
    margin-top: 5px;
    min-width: 250px;
    font-size: 0.9rem;
  }
`

const Icons = styled.div`
  display: flex;
  > * {
    margin-right: 15px;
  }
  > :last-child {
    margin-right: 0;
  }
`

interface IProps {
  url?: string
}

const Socials = (props: IProps) => {
  const [displayed, setDisplayed] = useState(false)

  const link =
    typeof window !== "undefined" && `${props.url || window.location.href}`

  return (
    <Wrapper>
      <Trigger onClick={() => setDisplayed(d => !d)}>
        <LinkIcon color="#fff" />
        <Twitter color="#fff" />
        <Facebook color="#fff" />
      </Trigger>
      {displayed && (
        <>
          <Overlay onClick={() => setDisplayed(false)} />
          <Dropdown onClick={e => e.stopPropagation()}>
            <Title>Share this with</Title>
            <Element
              href={`http://www.facebook.com/sharer/sharer.php?u=${link}`}
              target="_blank"
              rel="noreferrer noopener"
            >
              <Social>
                <Facebook size="20px" color="#30508B" />
              </Social>
              <div>Facebook</div>
            </Element>
            <Element
              href={`http://twitter.com/share?url=${link}&via=nightzookeeper`}
              target="_blank"
              rel="noreferrer noopener"
            >
              <Social>
                <Twitter size="20px" color="#0197EC" />
              </Social>
              <div>Twitter</div>
            </Element>
            <CopyLink>
              <div>Copy this link</div>
              <input type="text" value={link || ""} />
            </CopyLink>
            <Title>Follow us on</Title>
            <Icons>
              <a
                href="https://facebook.com/nightzookeeper"
                target="_blank"
                rel="noreferrer noopener"
              >
                <Social>
                  <Facebook size="20px" color="#30508B" />
                </Social>
              </a>
              <a
                href="https//twitter.com/nightzookeeper"
                target="_blank"
                rel="noreferrer noopener"
              >
                <Social>
                  <Twitter size="20px" color="#0197EC" />
                </Social>
              </a>
              <a
                href="https://instagram.com/nightzookeeper"
                target="_blank"
                rel="noreferrer noopener"
              >
                <Social>
                  <Instagram size="20px" color="#E92974" />
                </Social>
              </a>
              <a
                href="https://pinterest.com/nightzookeeper"
                target="_blank"
                rel="noreferrer noopener"
              >
                <Social>
                  <Pinterest size="20px" color="#BD0C21" />
                </Social>
              </a>
            </Icons>
          </Dropdown>
        </>
      )}
    </Wrapper>
  )
}

Socials.defaultProps = {
  url: null,
}

export default Socials
