import { useLocation } from "@reach/router"
import { navigate } from "gatsby"
import { StaticImage } from "gatsby-plugin-image"
import { More } from "grommet-icons"
import React, { useEffect, useMemo, useState } from "react"
import styled, { css } from "styled-components"
import useCurrentUser from "../../hooks/useCurrentUser"
import LanguageDropdown from "../LanguageDropdown"
import LinkComponent from "../Link"
import useLoginModal from "../LoginModal/useLoginModal"
import useRegisterModal from "../RegisterModal/useRegisterModal"
import scrollToPlansSection from "../Strapi/actions/scrollToPlansSection"
import Button from "../UI/Button"
import Socials from "../UI/Socials"
import Dropdown from "./Dropdown"

const Wrapper = styled.div`
  height: 50px;
  background-color: rgba(0, 0, 0, 0.3);
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  z-index: 10;
  display: flex;
  font-size: 1.5rem;
  align-items: center;
  justify-content: space-between;
  padding: 0 25px;
  color: #fff;
  z-index: 20;
`

const Brand = styled.div``

const Links = styled.div`
  display: flex;
  align-items: center;
  > * {
    margin-right: 20px;
    cursor: pointer;
  }
  > :last-child {
    margin-right: 0;
  }
  a {
    color: inherit;
    text-decoration: none;
  }
  @media (max-width: 924px) {
    display: none;
  }
`

const MobileLinks = styled.div`
  position: relative;
  z-index: 999;
  @media (min-width: 924px) {
    display: none;
  }
  > :nth-child(1) {
    height: 50px;
    display: flex;
    align-items: center;
    cursor: pointer;
  }
  > :nth-child(2) {
    position: fixed;
    z-index: 99000;
    top: 50px;
    text-align: center;
    width: 100%;
    left: 0;
    display: flex;
    flex-direction: column;
    background-color: #000000ee;
    transform: translateY(0);
    ${(props: { displayed: boolean }) =>
      !props.displayed &&
      css`
        transform: translateY(calc(-100% - 50px));
      `}
    transition: transform 0.2s ease-in-out;
    > * {
      padding: 10px 50px;
      display: flex;
      justify-content: center;
      border-bottom: 1px dashed #ffffff22;
      :hover {
        background-color: #ffffff22;
      }
    }
    > :last-child {
      border-bottom: none;
    }
    > :nth-child(2n + 1) {
    }
    font-size: 0.8em;
  }
  > :last-child {
    z-index: -1;
  }
`

const LinkItem = styled.div`
  cursor: pointer;
`

const Overlay = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
`

interface ILink {
  placeholder: string
  target: string
  children?: any
  external: boolean
}

interface IProps {
  links?: {
    id: number
    link: ILink
    linkGroup: { label: string; links: ILink[] }
  }[]
  languageDropdownOptions?: object
  locale?: string
  availableLangs?: string[]
}

const HeaderUI = (props: IProps) => {
  const currentUser = useCurrentUser()
  const [loginModal, toggleLoginModal] = useLoginModal()
  const [registerModal, toggleRegisterModal] = useRegisterModal()
  const [menuDisplayed, setMenuDisplayed] = useState(false)
  const location = useLocation()

  useEffect(() => {
    if (typeof window !== "undefined") {
      const onScroll = () => {
        const navEl = document.getElementById("nav") as HTMLElement
        const color = navEl.style.background
        if (window.pageYOffset > 100 && color !== "rgba(0, 0, 0, 0.8)") {
          navEl.style.background = "rgba(0,0,0,0.8)"
        } else if (
          window.pageYOffset <= 100 &&
          color !== "rgba(0, 0, 0, 0.3)"
        ) {
          navEl.style.background = "rgba(0,0,0,0.3)"
        }
      }
      window.addEventListener("scroll", onScroll)
      return () => {
        window.removeEventListener("scroll", onScroll)
      }
    }
  }, [])

  const specialButton = useMemo(() => {
    if (/\/parents/.test(location.pathname)) {
      return (
        <Button
          theme="primary"
          size="x-small"
          onClick={() => scrollToPlansSection()}
        >
          Start trial
        </Button>
      )
    }
    if (/\/teachers$/.test(location.pathname)) {
      return (
        <LinkComponent target="/teachers/start">
          <Button theme="primary" size="x-small">
            Request Free Trial
          </Button>
        </LinkComponent>
      )
    }
    return (
      <Button
        theme="primary"
        size="x-small"
        onClick={() => toggleRegisterModal()}
      >
        Register
      </Button>
    )
  }, [location])

  return (
    <Wrapper id="nav">
      {loginModal}
      {registerModal}
      <Brand>
        <LinkComponent target="/">
          <StaticImage
            src="../../images/logo.svg"
            alt="Logo"
            width={32}
            height={32}
            placeholder="tracedSVG"
          />
        </LinkComponent>
      </Brand>
      <Links>
        {(props.links || []).map((l, i) => {
          if (l.link) return <LinkComponent key={i} {...l.link} />
          if (l.linkGroup)
            return (
              <Dropdown
                key={i}
                label={l.linkGroup.label}
                links={l.linkGroup.links}
              />
            )
          return null
        })}

        {!currentUser && (
          <div
            onClick={() => toggleLoginModal()}
            onKeyPress={e => e.which === 13 && toggleLoginModal()}
            role="button"
            tabIndex={0}
          >
            Login
          </div>
        )}
        {!currentUser && specialButton}
        {currentUser && currentUser.type === "parent" && (
          <a href="https://dashboard.nightzookeeper.com">
            <Button theme="primary" size="x-small">
              {currentUser.name}
            </Button>
          </a>
        )}
        {currentUser && currentUser.type === "student" && (
          <a href="https://nightzookeeper.com/edu/zoo/map">
            <Button theme="primary" size="x-small">
              {currentUser.username}
            </Button>
          </a>
        )}
        {(props.availableLangs || []).length > 0 && (
          <LanguageDropdown
            {...(props.languageDropdownOptions || {})}
            locales={props.availableLangs || []}
            currentLocale={props.locale || "en"}
          />
        )}
        <Socials />
      </Links>
      <MobileLinks displayed={menuDisplayed}>
        <div
          onClick={() => setMenuDisplayed(d => !d)}
          onKeyPress={e => e.which === 13 && setMenuDisplayed(d => !d)}
          role="button"
          tabIndex={0}
        >
          <More color="#fff" />
        </div>
        <div>
          {(props.links || []).map((l, i) => {
            if (l.link)
              return (
                <LinkItem
                  key={`m-${i}`}
                  onClick={() => navigate(l.link.target)}
                >
                  {l.link.children || l.link.placeholder}
                </LinkItem>
              )
            if (l.linkGroup)
              return (
                <div key={`m-${i}`}>
                  <Dropdown
                    label={l.linkGroup.label}
                    links={l.linkGroup.links}
                  />
                </div>
              )
            return null
          })}
          <div>
            <div
              style={{ cursor: "pointer" }}
              onClick={() => toggleLoginModal()}
              onKeyPress={e => e.which === 13 && toggleLoginModal()}
              role="button"
              tabIndex={0}
            >
              Login
            </div>
          </div>
          <div>
            <Button
              theme="primary"
              size="x-small"
              onClick={() => toggleRegisterModal()}
            >
              Register
            </Button>
          </div>
          {(props.availableLangs || []).length > 0 && (
            <div>
              <LanguageDropdown
                locales={props.availableLangs || []}
                currentLocale={props.locale || "en"}
              />
            </div>
          )}
          <div>
            <Socials />
          </div>
        </div>
        {menuDisplayed && (
          <Overlay
            onClick={() => {
              setMenuDisplayed(false)
            }}
          />
        )}
      </MobileLinks>
    </Wrapper>
  )
}

HeaderUI.defaultProps = {
  links: [],
  locale: "en",
  availableLangs: [],
  languageDropdownOptions: {},
}

export default HeaderUI
