/**
 * Layout component that queries for data
 * with Gatsby's useStaticQuery component
 *
 * See: https://www.gatsbyjs.com/docs/use-static-query/
 */
import "@fontsource/libre-baskerville" // Defaults to weight 400 with all styles included.
import "@fontsource/rammetto-one"
import { useLocation } from "@reach/router"
import Cookies from "js-cookie"
import PropTypes from "prop-types"
import React, { useEffect } from "react"
import { ThemeProvider } from "styled-components"
import { AuthenticationStateProvider } from "../providers/AuthenticationProvider"
import useExitIntent from "../components/ExitIntent/useExitIntent"
import GlobalStyle from "../components/GlobalStyles"
import useHubspotTracking from "../hooks/useHubspotTracking"
import useReferral from "../hooks/useReferral"
import Apollo from "../providers/Apollo"
import { createTheme, DEFAULT_THEME } from "../styles/Theme"

const BareLayout = ({ children }) => {
  useReferral()
  useHubspotTracking()
  const location = useLocation()
  const { component } = useExitIntent()

  useEffect(() => {
    const urlParams = new URLSearchParams(location.search)
    if (urlParams.get("referrer")) {
      Cookies.set("nzk_referrer", urlParams.get("referrer"), {
        expires: 14,
        domain: ".nightzookeeper.com",
      })
    }
    // eslint-disable-next-line
  }, [])

  let THEME = DEFAULT_THEME
  if (/teachers/.test(location.pathname)) {
    THEME = createTheme({
      primary: "#8AC55C",
      secondary: "#006736",
    })
  }

  return (
    <>
      <GlobalStyle />
      <ThemeProvider theme={THEME}>
        <Apollo>
          <AuthenticationStateProvider>
            {component}
            {children}
          </AuthenticationStateProvider>
        </Apollo>
      </ThemeProvider>
    </>
  )
}

BareLayout.propTypes = {
  children: PropTypes.node.isRequired,
}

export default BareLayout
