const prodConfig = {
  env: "production",
  strapiUrl: process.env.STRAPI_URL || "https://strapi.nightzookeeper.com",
  stripeKey: "pk_live_3DaeYayFezHhyNjDyzM4Ayhe",
  shopStripeKey: "pk_live_3DaeYayFezHhyNjDyzM4Ayhe",
  graphqlEndpoint: "https://graphql.nightzookeeper.com/graphql",
  eduServerEndpoint: "https://graphql.nightzookeeper.com",
  paypalClientId:
    "ATrseDWmyEY0jRIch8Ztwz1vaO8ruM6p0PX4GtELci0BB2p_QDpvJsNe0SWaguotBIK-HV7of118DQ3l",
  bookGeneratorEndpoint: "https://nzk-pod-k5m5oqwe7a-nw.a.run.app",
}

const stagingConfig = {
  env: "staging",
  strapiUrl: process.env.STRAPI_URL || "https://strapi.nightzookeeper.com",
  stripeKey: "pk_live_3DaeYayFezHhyNjDyzM4Ayhe",
  shopStripeKey: "pk_test_hDJwlew9MOj05rvBATDoKc8Y",
  graphqlEndpoint: "https://graphql.staging.nightzookeeper.com/graphql",
  eduServerEndpoint: "https://graphql.staging.nightzookeeper.com",
  paypalClientId:
    "ATrseDWmyEY0jRIch8Ztwz1vaO8ruM6p0PX4GtELci0BB2p_QDpvJsNe0SWaguotBIK-HV7of118DQ3l",
  bookGeneratorEndpoint: "https://nzk-pod-k5m5oqwe7a-nw.a.run.app",
}

const devConfig = {
  ...prodConfig,
  env: "development",
  paypalClientId:
    "AfJy34Q9iQ4JQdfdeFjwrtzkDwq4iRqKGpprZsLJZEFlscxWp4TUf_OSEBGSTpkkf3ebAGjCPKHeW5vd",
  strapiUrl: process.env.STRAPI_URL || "http://localhost:1337",
  stripeKey: "pk_test_hDJwlew9MOj05rvBATDoKc8Y",
  shopStripeKey: "pk_test_hDJwlew9MOj05rvBATDoKc8Y",
  graphqlEndpoint: "http://localhost:8080/graphql",
  bookGeneratorEndpoint: "http://localhost:3000",
  eduServerEndpoint: "http://localhost:8080",
}

const configs = {
  production: prodConfig,
  staging: stagingConfig,
  development: devConfig,
}


const env =
  process.env.BRANCH === "feature/teacherflow"
  ? "staging"
  : process.env.GATSBY_ENV

const config = configs[env] || prodConfig

module.exports = config
