import { base } from 'grommet-icons';
import { css } from 'styled-components';
import deepMerge from '../utils/deepMerge';

interface FontStyles {
  fontSize: string,
  color: string,
  fontFamily: string,
  fontWeight: string
}

export const FONT_FAMILIES = {
  libreBaskerville: css`
    font-family: "Libre Baskerville";
    font-smooth: always;
    font-feature-settings: normal;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
  `,
  rammetto: css`
    font-family: Rammetto One;
    font-smooth: always;
    font-feature-settings: normal;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
  `
}

export const FONT_SIZES = {
  massive: '4.5rem',
  xLarge: '2.5rem',
  large: '1.5rem',
  regular: '1rem',
  small: '0.8rem',
  xSmall: '0.7rem'
}
interface PageColors {
  primary: string
}

export interface Theme {
  primary: string,
  secondary: string
}

export const createTheme = (theme: Theme) => deepMerge(base, theme)

export const DEFAULT_THEME: Theme = createTheme({
  primary: '#FF520F',
  secondary: '#FF520F'
})