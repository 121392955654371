import {
  ApolloClient,
  ApolloProvider,
  createHttpLink,
  InMemoryCache,
} from "@apollo/client"
import { setContext } from "@apollo/client/link/context"
import fetch from "isomorphic-fetch"
import Cookies from "js-cookie"
import React from "react"
import config from "../../config"

export const getToken = () => {
  let rawToken = Cookies.get("nzk-edu-token")
  let token
  if (rawToken && rawToken !== "null") {
    rawToken = rawToken.replace(/%22/g, '"').replace(/%2C/g, ",")
    token = JSON.parse(rawToken).accessToken
  }
  if (token && new Date(token.expiresAt).getTime() > Date.now()) {
    return token
  }
}
export default ({ children }: { children: any }) => {
  const httpLink = createHttpLink({
    fetch,
    uri: config.graphqlEndpoint,
  })

  const authLink = setContext((_, { headers }) => {
    const token = getToken()
    return {
      headers: {
        ...headers,
        authorization: token ? `Bearer ${token.value}` : "",
      },
    }
  })

  const client = new ApolloClient({
    cache: new InMemoryCache(),
    link: authLink.concat(httpLink),
  })

  return <ApolloProvider client={client}>{children}</ApolloProvider>
}
