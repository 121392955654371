export const scrollToPlansSection = (className?: string) => {
  if (typeof window === "undefined") return
  const target: HTMLElement | null = document.querySelector(
    className || ".PlansSection"
  )
  if (!target) return
  window.scrollTo({
    top: target.offsetTop - target.offsetHeight / 2,
    behavior: "smooth",
  })
}

export default scrollToPlansSection
