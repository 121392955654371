import React from 'react'
import { Link as GatsbyLink } from "gatsby"

interface IProps {
  target: string,
  placeholder?: string,
  external?: boolean,
  children?: any
}

const Link = (props: IProps) => {
  if (props.external) return <a href={props.target}>{props.children || props.placeholder}</a>
  return <GatsbyLink to={props.target}>{props.children || props.placeholder}</GatsbyLink>
}

export default Link
